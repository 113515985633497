<template>
  <el-form
    class="demo-ruleForm"
    :model="form"
    :rules="rules"
    ref="ruleFormRef"
    status-icon
  >
    <el-form-item label="姓名" prop="name">
      <el-input style="width: 120px" v-model.trim="form.name" />
    </el-form-item>

    <el-form-item label="账号" prop="account">
      <el-input
        class="textarea"
        style="width: 240px"
        :rows="2"
        type="textarea"
        clearable
        placeholder="支持同时录入多账号"
        v-model.trim="form.account"
      />
    </el-form-item>
    <el-text type="danger" id="subheadEdit" size="small"
      >多账号请使用 ` / ` 隔开</el-text
    >
    <el-button
      id="Editbutton"
      type="primary"
      @click="Editkefu"
      :icon="Check"
      plain
      round
      >确定修改
    </el-button>
  </el-form>
</template>

<style lang="less" scoped>
#subheadEdit {
  position: relative;
  left: 50px;
  bottom: 20px;
}
#Editbutton {
  position: relative;
  left: 60px;
  margin-bottom: 0px;
}
</style>

<script setup>
import { reactive, ref, watch } from "vue";
import { Check } from "@element-plus/icons-vue";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";
import axios from "axios";
const store = useStore();

// 客服添加表单
const form = reactive({
  name: "",
  account: "",
  _id: null,
});
watch(
  () => store.state.kefuEdit,
  (newValue, oldValue) => {
    const { name, accountList, _id } = newValue;
    if (newValue !== 1) {
      form.name = name;
      form.account = accountList.join("/");
      form._id = _id;
    }
  },
  { deep: true }
);
// 表单验证
const ruleFormRef = ref();
const rules = reactive({
  name: [
    {
      required: true,
      message: "姓名不能为空",
      trigger: "blur",
    },
  ],
  account: [
    {
      required: true,
      message: "账号不能为空",
      trigger: "blur",
    },
  ],
});

const Editkefu = () => {
  ruleFormRef.value.validate(async (value) => {
    if (value) {
      form.account = form.account.replace(/^\/+|\/+$/g, "");
      try {
        const res = await axios.put("/adminapi/kefu/put", form);
        if (res.data.code === 1) {
          ElMessage({
            message: `客服【${form.name}】信息修改成功`,
            type: "success",
          });
        }
        store.commit("changKefuEdit", 1);
      } catch (error) {
        ElMessage({
          message: `修改失败，系统出问题了，快去找浅白。。。`,
          type: "warning",
        });
      }
    }
  });
};
</script>
