<template>
  <div class="header">
    <el-page-header icon="" title="账号管理">
      <template #content>
        <span class="text-Medium font-600 mr-3"> 账号管理 </span>
      </template>
    </el-page-header>
  </div>
  <div>
    <el-card :key="componentKey" shadow="never" style="max-width: 1550px">
      <template #header>
        <div id="card-header">
          <span>游戏账号列表</span>
          <el-input
            v-model.trim="inputKeyword"
            style="width: 240px"
            placeholder="请输入关键词"
            clearable
            :prefix-icon="Search"
            @keyup.enter.native="search"
          />
          <el-button
            type="primary"
            :icon="Search"
            class="search"
            @keyupnative="search"
            @click="search"
            >查找</el-button
          >

          <el-checkbox
            v-model="checked"
            label="仅显示上架中的账号"
            @change="isChecked"
            size="large"
          />
        </div>
      </template>

      <!-- 分页 arr.silce((当前页-1)*每页条数，当前页数*当前条数)-->
      <el-table
        v-loading="loading"
        :element-loading-svg="svg"
        class="custom-loading-svg"
        element-loading-svg-view-box="-10, -10, 50, 50"
        :data="
          tableData.slice(
            (searchData.page - 1) * searchData.limit,
            searchData.page * searchData.limit
          )
        "
        style="width: 100%"
      >
        <el-table-column
          prop="date"
          label="时间"
          header-align="center"
          align="center"
          width="110"
        />
        <el-table-column
          prop="salesType"
          header-align="center"
          align="center"
          label="销售类型"
          width="90"
        />
        <el-table-column prop="number" fixed label="编号" width="80" />
        <el-table-column prop="server" label="大区" width="130" />
        <el-table-column prop="title" label="标题" width="220" />
        <el-table-column
          label="图片"
          width="100"
          header-align="center"
          align="center"
        >
          <template #default="scope">
            <el-button type="primary" @click="handleView(scope.row)" link
              >查看</el-button
            >
          </template>
        </el-table-column>

        <el-table-column
          prop="Price"
          header-align="center"
          align="center"
          label="售价"
          width="80"
        >
          <template #default="scope">
            <div style="color: red; font-weight: 600">
              {{ scope.row[higher_ups + "Price"] }}
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="AgencyPrice"
          header-align="center"
          align="center"
          label="代理拿货价"
          width="100"
        >
          <template #default="scope">
            <div style="color: #009acd; font-weight: 600">
              {{ scope.row.AgencyPrice }}
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="clerk"
          header-align="center"
          align="center"
          label="收购客服"
          width="90"
        />

        <el-table-column fixed="right" label="首页置顶" width="110">
          <template #default="scope">
            <el-popconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              title="你确定要执行该操作吗？"
              @confirm="isTopChange(scope.row)"
            >
              <template #reference>
                <el-switch
                  v-model="scope.row.isTop"
                  size="large"
                  width="60"
                  inline-prompt
                  active-text="置顶"
                  inactive-text="取消"
                  :before-change="beforeChangeIsTop"
                />
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>

        <el-table-column
          fixed="right"
          label="状态管理"
          header-align="center"
          width="160"
        >
          <template #default="scope">
            <el-popconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              title="你确定要执行该操作吗？"
              @confirm="isShelvesChange(scope.row)"
            >
              <template #reference>
                <el-switch
                  v-model="scope.row.isShelves"
                  size="large"
                  width="60"
                  inline-prompt
                  style="--el-switch-on-color: #ff4949"
                  active-text="上架"
                  inactive-text="下架"
                  :before-change="beforeChangeSwitch"
                />
              </template>
            </el-popconfirm>

            <el-button style="margin-left: 10px" @click="handleEdit(scope.row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        layout="total,sizes,prev,pager,next"
        :currentPage="searchData.page"
        @page-size="searchData.limit"
        :page-sizes="[5, 10, 15, 30]"
        :total="searchData.total"
        :default-page-size="5"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
    <el-image-viewer
      v-if="showViewer"
      @close="colseViewer"
      :url-list="srcList"
      :hide-on-click-modal="true"
    />

    <el-dialog
      v-model="dialogTableVisible"
      top="5vh"
      :title="'编号【' + titeNumber + '】'"
      draggable
      width="500"
    >
      <gameEditModel @changeGameEdit="changeGameEdit"> </gameEditModel>
    </el-dialog>
  </div>
</template>

<style lang="less" scoped>
.header {
  padding-top: 2px;
  margin-bottom: 10px;
}

.search {
  margin-left: 10px;
}

.el-pagination {
  margin-top: 15px;
}

::v-deep .el-image-viewer__img {
  width: 80%;
  height: 80%;
  object-fit: contain;
}

.el-checkbox {
  margin-left: 30px;
}

::v-deep .el-dialog {
  border-radius: 30px;
}

::v-deep .box-card {
  border-radius: 30px;
}

#card-header span {
  font-size: 20px;
  font-weight: 550;
  color: #606266;
  margin-right: 50px;
}
</style>
<style lang="less">
::v-deep .el-image-viewer__img {
  width: 80%;
  height: 80%;
  object-fit: contain;
}
</style>
<script setup>
import { ref, onMounted, reactive, watch } from "vue";
import { Search } from "@element-plus/icons-vue";
import { ElMessage, ElNotification } from "element-plus";
import { useRouter } from "vue-router";
import axios from "axios";
import gameEditModel from "@/components/gamefrom/gameEditModel.vue";
import { useStore } from "vuex";
const showViewer = ref(false);

// 路由跳转刷新 通过给组件更新key 重新渲染
const router = useRouter();
const componentKey = ref(0);
watch(
  () => router.currentRoute.value.query,
  (newParams, oldParams) => {
    if (JSON.stringify(newParams) !== "{}") {
      componentKey.value += 1;
    }
  },
  {
    immediate: true,
    deep: true,
  }
);

// 进入页面获取数据onMounted
const tableData = ref([]);
const higher_ups = ref();
onMounted(() => {
  getTableData();
});

// 分页
const searchData = reactive({
  page: 1,
  limit: 5,
  total: 0,
});

// 每页条数
const handleSizeChange = (value) => {
  searchData.limit = value;
};

// 页数
const handleCurrentChange = (value) => {
  searchData.page = value;
};
// 全部列表渲染
const getTableData = async () => {
  try {
    loading.value = true;
    const res = await axios.get("adminapi/game/cf/list");
    if (res.data.code === 1) {
      loading.value = false;
      tableData.value = res.data.data;
      higher_ups.value = res.data.higher_ups;
      searchData.total = tableData.value.length;
    } else {
      ElMessage.error("账号列表获取失败，" + res.data.err);
    }
  } catch (err) {
    ElMessage.error("账号列表获取失败，" + err);
  }
};

// 点击查看图片
let srcList = [];
const handleView = (data) => {
  srcList = data.imageList;
  srcList.splice(0, 0, data.mainImage);
  showViewer.value = true;
};
const colseViewer = () => {
  srcList = [];
  showViewer.value = false;
};

// 上下架切换
const confirmButton = ref(false);
const beforeChangeSwitch = (data) => {
  return new Promise((resolve) => {
    const unwatch = watch(
      () => confirmButton.value,
      () => {
        if (confirmButton.value) {
          resolve(true);
          unwatch();
          confirmButton.value = false;
        }
      }
    );
  });
};
const isShelvesChange = async (data) => {
  confirmButton.value = true;
  const { _id, isShelves, isTop, number } = data;
  let reqBody = {};
  try {
    if (isShelves && isTop) {
      reqBody = {
        _id,
        isShelves: !isShelves,
        isTop: !isTop,
      };
    } else {
      reqBody = {
        _id,
        isShelves: !isShelves,
      };
    }
    const res = await axios.put(`adminapi/game/cf/list/${_id}`, reqBody);
    if (res.data.code === 1) {
      let strShelves;
      if (!isShelves) {
        strShelves = "上架";
      } else if (isShelves) {
        strShelves = "下架";
        if (isTop) getTableData();
      }
      ElNotification({
        title: "成功",
        message: `编号【${number}】${strShelves}成功`,
        type: "success",
      });
    } else {
      ElMessage.error("后端操作错误" + res.data.err);
    }
  } catch (err) {
    ElMessage.error("系统错误操作失败" + err);
  }
};

// 置顶
const isTopConfirmButton = ref(false);
const beforeChangeIsTop = (data) => {
  return new Promise((resolve) => {
    const unwatch = watch(
      () => isTopConfirmButton.value,
      () => {
        if (isTopConfirmButton.value) {
          resolve(true);
          unwatch();
          isTopConfirmButton.value = false;
        }
      }
    );
  });
};
const isTopChange = async (data) => {
  isTopConfirmButton.value = true;
  const { _id, isTop, isShelves, number } = data;
  let reqBody = {};
  try {
    if (!isShelves && !isTop) {
      reqBody = {
        _id,
        isShelves: !isShelves,
        isTop: !isTop,
      };
    } else {
      reqBody = {
        _id,
        isTop: !isTop,
      };
    }
    console.log(reqBody);
    const res = await axios.put(`adminapi/game/cf/list/${_id}`, reqBody);
    if (res.data.code === 1) {
      let strTop;
      if (!isTop) {
        if (!isShelves) getTableData();
        strTop = "首页置顶";
      } else {
        strTop = "取消首页置顶";
      }
      ElNotification({
        title: "成功",
        message: `编号【${number}】${strTop}成功`,
        type: "success",
      });
    } else {
      ElMessage.error("系统错误操作失败" + res.data.err);
    }
  } catch (err) {
    console.log(err);
    ElMessage.error("系统错误操作失败" + err);
  }
};
// 搜索
const inputKeyword = ref();
const search = async () => {
  if (!inputKeyword.value) {
    ElMessage({
      showClose: true,
      message: "请输入关键词搜索",
      center: true,
    });
  } else {
    try {
      loading.value = true;
      const res = await axios.post("/adminapi/game/cf/search", {
        keyword: inputKeyword.value,
      });
      if (res.data.code === 1) {
        loading.value = false;
        tableData.value = res.data.data;
        searchData.total = tableData.value.length;
      } else {
        ElMessage.error("查找失败，" + res.data.err);
      }
    } catch (err) {
      ElMessage.error("查找失败，" + err);
    }
  }
};

// 监听输入框是否为空
watch(
  () => inputKeyword.value,
  (newValue) => {
    if (!newValue) {
      getTableData();
    }
  }
);

// 仅显示上架中账号
const checked = ref(false);
const dataReserve = ref();
const isChecked = (value) => {
  if (value) {
    dataReserve.value = tableData.value;
    tableData.value = tableData.value.filter((item) => item.isShelves == true);
  } else {
    tableData.value = dataReserve.value;
  }
  searchData.total = tableData.value.length;
};

// 编辑
const store = useStore();
const dialogTableVisible = ref(false);
const changeGameEdit = (value) => {
  dialogTableVisible.value = value;
  getTableData();
};
let titeNumber;
const handleEdit = (data) => {
  dialogTableVisible.value = true;
  titeNumber = data.number;
  store.state.gameEditId = data._id;
};

// 加载动画
const loading = ref(true);
const svg = `
        <path class="path" d="
          M 30 15
          L 28 17
          M 25.61 25.61
          A 15 15, 0, 0, 1, 15 30
          A 15 15, 0, 1, 1, 27.99 7.5
          L 15 15
        " style="stroke-width: 4px; fill: rgba(0, 0, 0, 0)"/>
      `;
</script>
