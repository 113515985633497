import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import locale from "element-plus/es/locale/lang/zh-cn";
// 输入框输入限制
import inputDirective from "vue-el-input-directive";
//导入axios拦截器
import "./util/axios.config";
// 通过版本号清除浏览器缓存
import { version } from "../package.json";
const vers = window.localStorage.getItem("Version");
if (version != vers) {
  localStorage.clear();
  window.localStorage.setItem("Version", version);
  window.location.reload();
}
createApp(App)
  .use(ElementPlus, { locale })
  .use(store)
  .use(router)
  .use(inputDirective)
  .mount("#app");
