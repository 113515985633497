<template>
  <div class="header">
    <el-page-header icon="" title="趣兔后台管理系统">
      <template #content>
        <span class="text-Medium font-600 mr-3"> 员工操作日志 </span>
      </template>
    </el-page-header>
  </div>

  <div class="main">
    <el-card shadow="hover" class="box-card" style="max-width: 1100px">
      <template #header>
        <div id="card-header">
          <span>操作日志</span>
        </div>
      </template>
      <el-table
        v-loading="loading"
        :element-loading-svg="svg"
        class="custom-loading-svg"
        element-loading-svg-view-box="-10, -10, 50, 50"
        :data="tableData"
        style="width: 100%"
      >
	  <el-table-column
	    prop="username"
	    label="时间"
	    header-align="center"
	    align="center"
	    width="140"
	  />
        <el-table-column
          prop="username"
          label="用户名"
          header-align="center"
          align="center"
          width="140"
        />
		

        <el-table-column
          prop="petname"
          label="昵称"
          header-align="center"
          align="center"
          width="110"
        />

    
		
        <el-table-column
          prop="name"
          label="姓名"
          header-align="center"
          align="center"
          width="110"
        />
		<el-table-column
		  label="职位"
		  header-align="center"
		  align="center"
		  width="110"
		>
		  <template #default="scope">
		    <el-tag v-if="scope.row.role === 1" size="large" type="danger"
		      >管理员</el-tag
		    >
		    <el-tag v-else-if="scope.row.role === 2" size="large" type="success"
		      >资料审核</el-tag
		    >
		    <el-tag v-else-if="scope.row.role === 3" size="large" type="warning"
		      >活动管理</el-tag
		    >
		    <el-tag v-else-if="scope.row.role === 4" size="large" type="primary"
		      >评估主播</el-tag
		    >
		  </template>
		</el-table-column>
		
        <el-table-column prop="phoneNumber" label="操作项目" width="150" />
        
      </el-table>
    </el-card>
  </div>
</template>

<style lang="less" scoped>
.header {
  padding-top: 2px;
  margin-bottom: 10px;
}
#card-header span {
  font-size: 20px;
  font-weight: 550;
  color: #606266;
  margin-right: 50px;
}

::v-deep .el-dropdown__popper {
  border-radius: 20px;
}
</style>

<script setup>
import { ElMessage } from "element-plus";
import { ref, onMounted, reactive } from "vue";
import axios from "axios";

const tableData = ref([]);
// 进入页面便获取数据
onMounted(() => {
  getTableData();
});

// 获取所有用户信息
const getTableData = async () => {
  try {
    loading.value = true;
    const res = await axios.get("adminapi/user/list");
    if (res.data.code === 1) {
      tableData.value = res.data.data;
      loading.value = false;
    } else {
      ElMessage.error("数据获取失败，" + res.data.err);
    }
  } catch (err) {
    ElMessage.error("数据获取失败，" + err);
  }
};



// 加载动画
//加载动画
const loading = ref(true);
const svg = `
        <path class="path" d="
          M 30 15
          L 28 17
          M 25.61 25.61
          A 15 15, 0, 0, 1, 15 30
          A 15 15, 0, 1, 1, 27.99 7.5
          L 15 15
        " style="stroke-width: 4px; fill: rgba(0, 0, 0, 0)"/>
      `;
</script>
