<template>
  <div id="header">
    <el-page-header icon="" title="卖家资料">
      <template #content>
        <span class="text-Medium font-600 mr-3"> 资料列表 </span>
      </template>
    </el-page-header>
  </div>
  <el-card shadow="hover" style="max-width: 1980px">
    <template #header>
      <div id="card-header">
        <span>卖家资料列表</span>
        <el-input
          v-model="searchKeyword"
          style="width: 240px"
          placeholder="请输入关键词"
          clearable
          :prefix-icon="Search"
          @keyup.enter.native="search"
        />
        <el-button type="primary" :icon="Search" class="search" @click="search"
          >查找</el-button
        >
      </div>
    </template>

    <el-table
      v-loading="loading"
      :element-loading-svg="svg"
      class="custom-loading-svg"
      element-loading-svg-view-box="-10, -10, 50, 50"
      :key="componentKey"
      :data="
        tableData.slice(
          (searchData.page - 1) * searchData.limit,
          searchData.page * searchData.limit
        )
      "
      style="width: 100%"
    >
      <el-table-column
        prop="date"
        label="时间"
        header-align="center"
        align="center"
        width="110"
      />
      <el-table-column
        prop="qqNumber"
        label="交易账号"
        header-align="center"
        align="center"
        fixed="left"
        width="120"
      />
      <el-table-column
        prop="name"
        label="姓名"
        header-align="center"
        align="center"
        width="120"
      />
      <el-table-column prop="phoneNumber" label="手机号" width="120" />
      <el-table-column prop="IDCard" label="身份证号" width="180" />
      <el-table-column prop="father" label="父亲信息" width="180" />
      <el-table-column prop="mother" label="母亲信息" width="180" />
      <el-table-column prop="location" label="定位" width="180" />
      <el-table-column prop="notes" label="备注" width="180" />
      <el-table-column
        prop="IDCardImage"
        label="身份证正反面"
        header-align="center"
        align="center"
        width="70"
      >
        <template #default="scope">
          <el-button
            type="primary"
            @click="handleView('IDCardImage', scope.row)"
            link
            >查看</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="chatHistory"
        label="父母聊天记录"
        header-align="center"
        align="center"
        width="90"
      >
        <template #default="scope">
          <el-button
            type="primary"
            @click="handleView('chatHistory', scope.row)"
            link
            >查看</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="takeoutOrders"
        label="外卖订单"
        width="100"
        header-align="center"
        align="center"
      >
        <template #default="scope">
          <el-button
            type="primary"
            @click="handleView('takeoutOrders', scope.row)"
            link
            >查看</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="name"
        label="共享位置"
        width="100"
        header-align="center"
        align="center"
      >
        <template #default="scope">
          <el-button
            type="primary"
            @click="handleView('sharedPosition', scope.row)"
            link
            >查看</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout="total,sizes,prev,pager,next"
      :currentPage="searchData.page"
      @page-size="searchData.limit"
      :page-sizes="[5, 10, 15, 30]"
      :total="searchData.total"
      :default-page-size="5"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </el-card>
  <!-- 图片查看器 -->
  <div>
    <el-image-viewer
      v-if="showViewer"
      @close="colseViewer"
      :url-list="srcList"
      :hide-on-click-modal="true"
    />
  </div>
</template>

<style lang="less" scoped>
#header {
  padding-top: 2px;
  margin-bottom: 10px;
}
#card-header span {
  font-size: 20px;
  font-weight: 550;
  color: #606266;
  margin-right: 50px;
}
.search {
  margin-left: 10px;
}
.el-pagination {
  margin-top: 15px;
}
::v-deep .el-image-viewer__canvas .el-image-viewer__img {
  width: 80%;
  height: 80%;
  object-fit: contain;
}
</style>

<script setup>
import axios from "axios";
import { onMounted, ref, watch, reactive } from "vue";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";
import { Search } from "@element-plus/icons-vue";

// 路由跳转刷新 通过给组件更新key 重新渲染
const router = useRouter();
const componentKey = ref(0);
watch(
  () => router.currentRoute.value.query,
  (newParams, oldParams) => {
    if (JSON.stringify(newParams) !== "{}") {
      componentKey.value += 1;
    }
  },
  { immediate: true, deep: true }
);
// 进入页面获取数据
onMounted(() => {
  getTableDate();
});
// 数据获取渲染
const tableData = ref([]);
const getTableDate = async () => {
  try {
    loading.value = true;
    const res = await axios.get("adminapi/seller/list");
    loading.value = false;
    if (res.data.code === 1) {
      tableData.value = res.data.data;
      searchData.total = tableData.value.length;
    } else {
      ElMessage.error("数据获取失败，" + err);
    }
  } catch (err) {
    ElMessage.error("数据获取失败，" + err);
  }
};

// 点击查看图片
const showViewer = ref(false);
let srcList = [];

const handleView = (name, data) => {
  if (data[name]) {
    srcList = data[name];
  } else {
    srcList.push(data.IDCardBack, data.IDCardFront);
  }
  showViewer.value = true;
};
const colseViewer = () => {
  srcList = [];
  showViewer.value = false;
};
// 查找
const searchKeyword = ref();
const search = async () => {
  if (!searchKeyword.value) {
    ElMessage({
      showClose: true,
      message: "请输入关键词进行查找",
      center: true,
    });
  } else {
    try {
      loading.value = true;
      const res = await axios.post("adminapi/seller/search", {
        keyword: searchKeyword.value,
      });
      loading.value = false;
      if (res.data.code === 1) {
        tableData.value = res.data.data;
        searchData.total = tableData.value.length;
      } else {
        ElMessage.error("查找失败，" + res.data.err);
      }
    } catch (err) {
      ElMessage.error("查找失败，" + err);
    }
  }
};
// 监听输入框是否为空
watch(
  () => searchKeyword.value,
  (newValue) => {
    if (!newValue) {
      getTableDate();
    }
  }
);

/**
 * 分页
 */
const searchData = reactive({
  page: 1,
  limit: 5,
  total: 0,
});
// 每页条数
const handleSizeChange = (value) => {
  searchData.limit = value;
};
// 页数
const handleCurrentChange = (value) => {
  searchData.page = value;
};

//加载动画
const loading = ref(true);
const svg = `
        <path class="path" d="
          M 30 15
          L 28 17
          M 25.61 25.61
          A 15 15, 0, 0, 1, 15 30
          A 15 15, 0, 1, 1, 27.99 7.5
          L 15 15
        " style="stroke-width: 4px; fill: rgba(0, 0, 0, 0)"/>
      `;
</script>
