<template>
  <div class="header">
    <el-page-header icon="" title="卖家资料">
      <template #content>
        <span class="text-Medium font-600 mr-3"> 添加资料 </span>
      </template>
    </el-page-header>
  </div>
  <el-card shadow="hover" style="width: 820px">
    <template #header>
      <div id="card-header">
        <span>卖家资料信息</span>
        <!-- 添加备注 -->
        <el-popover
          :hide-after="1000"
          placement="bottom"
          trigger="click"
          :width="300"
        >
          <template #reference>
            <el-button link type="primary">
              添加备注
              <el-icon class="el-icon--right"><EditPen /></el-icon>
            </el-button>
          </template>

          <el-input
            v-model.trim="sellerAddForm.notes"
            :rows="3"
            type="textarea"
            maxlength="30"
            show-word-limit
            placeholder="请输入需要的备注信息"
          />
        </el-popover>
      </div>
    </template>
    <el-row>
      <el-col :span="12">
        <div class="subtitle">
          <User style="width: 25px; color: #0099ff; margin-right: 10px" />
          <p class="subtitle">个人信息</p>
        </div>
        <el-form
          ref="personRef"
          :model="sellerAddForm"
          :rules="sellerAddRules"
          label-position="right"
          label-width="auto"
          status-icon
        >
          <el-form-item label="交易QQ账号" prop="qqNumber">
            <el-input
              v-model.trim.number="sellerAddForm.qqNumber"
              style="width: 150px"
            />
          </el-form-item>

          <el-form-item label="姓名" prop="name">
            <el-input v-model.trim="sellerAddForm.name" style="width: 120px" />
          </el-form-item>

          <el-form-item label="手机号" prop="phoneNumber">
            <el-input
              v-model.trim.number="sellerAddForm.phoneNumber"
              style="width: 120px"
            />
          </el-form-item>

          <el-form-item label="身份证号" prop="IDCard">
            <el-input
              v-model.trim.number="sellerAddForm.IDCard"
              style="width: 200px"
            />
          </el-form-item>
        </el-form>

        <div class="subtitle">
          <Phone style="width: 25px; color: #0099ff; margin-right: 10px" />
          <p class="subtitle">紧急联系人</p>
        </div>
        <el-form
          ref="parentsRef"
          :rules="sellerAddRules"
          :model="sellerAddForm"
          label-position="top"
          label-width="auto"
          status-icon
        >
          <el-form-item label="父亲姓名+手机号" prop="father">
            <el-input
              v-model.trim="sellerAddForm.father"
              style="width: 180px"
            />
          </el-form-item>

          <el-form-item label="母亲姓名+手机号" prop="mother">
            <el-input
              v-model.trim="sellerAddForm.mother"
              style="width: 180px"
            />
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="12">
        <p class="idCardP">
          <el-icon v-if="frontImageTipUpload" class="tipWarnUpload">
            <QuestionFilled />
          </el-icon>
          <el-icon v-else class="tipCircleUpload"><CircleCheck /></el-icon>
          身份证正面
        </p>
        <el-upload
          class="avatar-uploader"
          action=" "
          :show-file-list="false"
          :auto-upload="false"
          accept=".jpg,.png"
          :on-change="frontHandleChange"
        >
          <img v-if="frontImageUrl" :src="frontImageUrl" class="idCardImage" />
          <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
        </el-upload>
        <p class="idCardP">
          <el-icon v-if="backImageTipUpload" class="tipWarnUpload">
            <QuestionFilled />
          </el-icon>
          <el-icon v-else class="tipCircleUpload"><CircleCheck /></el-icon>
          身份证反面
        </p>
        <el-upload
          class="avatar-uploader"
          action=" "
          :show-file-list="false"
          :auto-upload="false"
          accept=".jpg,.png"
          :on-change="backHandleChange"
        >
          <img v-if="backImageUrl" :src="backImageUrl" class="idCardImage" />
          <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
        </el-upload>
      </el-col>
    </el-row>
    <div class="subtitle">
      <Crop style="width: 25px; color: #0099ff; margin-right: 10px" />
      <p class="subtitle">相关截图</p>
    </div>
    <div class="screenshot">
      <el-row>
        <el-col :span="10">
          <p class="screenshot">
            <el-tooltip
              class="box-item"
              effect="light"
              content="至少上传两张截图"
              placement="top"
            >
              <el-icon v-if="chatHistoryTipUpload" class="tipWarnUpload"
                ><QuestionFilled
              /></el-icon>
              <el-icon v-else class="tipCircleUpload"><CircleCheck /></el-icon>
            </el-tooltip>

            聊天记录截图<el-text tag="sup" size="small"> 2张</el-text>
          </p>
          <el-upload
            class="upload-demo"
            multiple
            :limit="2"
            accept=".jpg,.png"
            list-type="picture-card"
            :on-change="chatHistoryChangeList"
            :on-remove="chatHistoryDeleteImgChange"
            :auto-upload="false"
            :class="{ disUploadSty: chatHistoryUpload }"
          >
            <el-icon><Plus /></el-icon>
          </el-upload>
        </el-col>
        <el-col :span="14">
          <p class="screenshot">
            <el-tooltip
              class="box-item"
              effect="light"
              content="至少上传两张截图"
              placement="top"
            >
              <el-icon v-if="takeoutOrdersTipUpload" class="tipWarnUpload"
                ><QuestionFilled
              /></el-icon>
              <el-icon v-else class="tipCircleUpload"><CircleCheck /></el-icon>
            </el-tooltip>
            外卖订单截图<el-text tag="sup" size="small"> 2~3张</el-text>
          </p>
          <el-upload
            class="upload-demo"
            multiple
            :limit="3"
            accept=".jpg,.png"
            list-type="picture-card"
            :auto-upload="false"
            :on-change="takeoutOrdersChangeList"
            :on-remove="takeoutOrdersDeleteImgChange"
            :class="{ disUploadSty: takeoutOrdersUpload }"
          >
            <el-icon><Plus /></el-icon>
          </el-upload>
        </el-col>
      </el-row>
      <p class="screenshot">
        <el-tooltip
          class="box-item"
          effect="light"
          content="至少上传两张截图"
          placement="top"
        >
          <el-icon v-if="sharedPositionTipUpload" class="tipWarnUpload"
            ><QuestionFilled
          /></el-icon>
          <el-icon v-else class="tipCircleUpload"><CircleCheck /></el-icon>
        </el-tooltip>
        共享定位截图<el-text tag="sup" size="small"> 3~4张</el-text>
      </p>
      <div class="submit">
        <el-upload
          class="upload-demo"
          multiple
          :limit="6"
          accept=".jpg,.png"
          list-type="picture-card"
          :auto-upload="false"
          :on-change="sharedPositionChangeList"
          :on-remove="sharedPositionDeleteImgChange"
          :class="{ disUploadSty: sharedPositionUpload }"
        >
          <el-icon><Plus /></el-icon>
        </el-upload>
        <el-button
          id="submit"
          :icon="FolderChecked"
          type="primary"
          @click="submit"
          >提交</el-button
        >
      </div>
    </div>
  </el-card>
</template>

<style lang="less" scoped>
.header {
  padding-top: 2px;
  margin-bottom: 10px;
}
div.subtitle {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
p.subtitle {
  font-weight: bolder;
  font-size: 16px;
  color: #0099ff;
}
::v-deep .el-form {
  margin-left: 15px;
}
::v-deep.avatar-uploader .idCardImage {
  width: 231.6px;
  height: 129.6px;
  display: block;
}
::v-deep.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

::v-deep.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #878888;
  width: 231.6px;
  height: 129.6px;
  text-align: center;
}
.idCardP {
  margin: 20px 0 10px 0;
  font-size: 15px;
  color: #606266;
}
div.screenshot {
  margin-left: 10px;
}
p.screenshot {
  font-size: 15px;
  margin: 20px 0 10px 0;
  color: #606266;
  width: fit-content;
}
.tipWarnUpload {
  color: #fd2020;
}
.tipCircleUpload {
  color: #0099ff;
}
::v-deep .el-upload--picture-card {
  width: 120px;
  height: 120px;
  background-color: #ffffff;
}
::v-deep li.el-upload-list__item {
  margin-bottom: 0;
}
::v-deep .el-upload-list--picture-card .el-upload-list__item {
  box-sizing: border-box !important;
  width: 120px !important;
  height: 120px !important;
  padding: 0;
}
::v-deep .disUploadSty .el-upload--picture-card {
  display: none; /* 上传按钮隐藏 */
}
div .submit {
  display: flex;
  justify-content: space-between;
}
#submit {
  position: relative;
  top: 80px;
  right: 20px;
  width: 80px;
  height: 40px;
  font-size: 15px;
}
#card-header {
  display: flex;
  justify-content: space-between;
  span {
    font-size: 20px;
    font-weight: 550;
    color: #606266;
  }
  .el-button {
    font-size: 15px;
  }
}
</style>

<script setup>
import { reactive, ref } from "vue";
import {
  Phone,
  User,
  Plus,
  Crop,
  FolderChecked,
  CircleCheck,
  QuestionFilled,
  EditPen,
} from "@element-plus/icons-vue";
import { ElMessage, ElNotification } from "element-plus";
import axios from "axios";
import { useRouter } from "vue-router";

/**
 * 一、响应式数据
 */
// 1.文字表单
const sellerAddForm = reactive({
  date: null,
  qqNumber: null,
  name: null,
  phoneNumber: null,
  IDCard: null,
  father: null,
  mother: null,
  notes: null,
});
// 2.图片表单
const fileForm = reactive({
  IDCardFront: null,
  IDCardBack: null,
  chatHistory: null,
  takeoutOrders: null,
  sharedPosition: null,
});

/*
 * 三、图片上传
 */
// 1. 上传文件前判断
const beforeUpload = (file) => {
  // 类型
  const isJPG = file.raw.type === "image/jpeg";
  const isPNG = file.raw.type === "image/png";
  // 大小
  const isLt4M = file.raw.size / 1024 / 1024 < 4;
  if (!(isJPG || isPNG)) {
    ElMessage.error("上传类型错误");
  }
  if (!isLt4M) {
    ElMessage.error("上传文件需小于4MB");
  }
  return (isJPG || isPNG) && isLt4M;
};

// 2. 身份证正反面
// 2.1正面
const frontImageUrl = ref();
const frontImageTipUpload = ref(true);
const frontHandleChange = (file) => {
  if (beforeUpload(file)) {
    frontImageUrl.value = URL.createObjectURL(file.raw);
    fileForm.IDCardFront = file;
    frontImageTipUpload.value = false;
  }
};
// 2.2反面
const backImageUrl = ref();
const backImageTipUpload = ref(true);
const backHandleChange = (file) => {
  if (beforeUpload(file)) {
    backImageUrl.value = URL.createObjectURL(file.raw);
    fileForm.IDCardBack = file;
    backImageTipUpload.value = false;
  }
};
// 3. 图片列表改变
// 3.0.1 列表变化函数封装
const ChangeList = (file, fileList, isUpload, tip, maxLimit, minLimit) => {
  if (!beforeUpload(file)) {
    fileList.splice(-1, 1);
  }
  isUpload.value = fileList.length >= maxLimit;
  tip.value = fileList.length < (minLimit ? minLimit : maxLimit);
};
const deleteImgChange = (fileList, isUpload, tip, maxLimit, minLimit) => {
  isUpload.value = fileList.length >= maxLimit;
  tip.value = fileList.length < (minLimit ? minLimit : maxLimit);
};

// 3.1 聊天记录截图
const chatHistoryUpload = ref(false);
const chatHistoryTipUpload = ref(true);
// 3.1.1上传（列表更新 提示图标更新 上传框更新）
const chatHistoryChangeList = (file, fileList) => {
  ChangeList(file, fileList, chatHistoryUpload, chatHistoryTipUpload, 2);
  fileForm.chatHistory = fileList;
};
// 3.1.2删除
const chatHistoryDeleteImgChange = (file, fileList) => {
  deleteImgChange(fileList, chatHistoryUpload, chatHistoryTipUpload, 2);
};

// 3.2外卖订单截图
const takeoutOrdersUpload = ref(false);
const takeoutOrdersTipUpload = ref(true);
const takeoutOrdersChangeList = (file, fileList) => {
  ChangeList(file, fileList, takeoutOrdersUpload, takeoutOrdersTipUpload, 3, 2);
  fileForm.takeoutOrders = fileList;
};
const takeoutOrdersDeleteImgChange = (file, fileList) => {
  deleteImgChange(fileList, takeoutOrdersUpload, takeoutOrdersTipUpload, 3, 2);
};
// 3.3共享定位截图
const sharedPositionUpload = ref(false);
const sharedPositionTipUpload = ref(true);

const sharedPositionChangeList = (file, fileList) => {
  ChangeList(
    file,
    fileList,
    sharedPositionUpload,
    sharedPositionTipUpload,
    4,
    3
  );
  fileForm.sharedPosition = fileList;
};
const sharedPositionDeleteImgChange = (file, fileList) => {
  deleteImgChange(
    fileList,
    sharedPositionUpload,
    sharedPositionTipUpload,
    4,
    3
  );
};

/**
 * 四、提交配置
 */
// 1.当前时间
const getNowDate = () => {
  const date = new Date();
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let strDate = date.getDate();
  if (month >= 1 && month < 9) month = "0" + month;
  if (strDate >= 1 && strDate <= 9) strDate = "0" + strDate;
  sellerAddForm.date = year + "-" + month + "-" + strDate;
};

// 2.类型转换
const switchFormData = () => {
  const params = new FormData();
  for (let key in sellerAddForm) {
    params.append(key, sellerAddForm[key]);
  }
  for (let key in fileForm) {
    if (fileForm[key].length) {
      for (let i = 0; i < fileForm[key].length; i++) {
        params.append(key, fileForm[key][i].raw);
      }
    } else {
      params.append(key, fileForm[key].raw);
    }
  }
  return params;
};

// 3.图片上传判断
const imageCheck = () => {
  try {
    const IDCardFrontCheck = fileForm.IDCardFront != null;
    const IDCardBackCheck = fileForm.IDCardBack != null;
    const chatHistoryCheck = fileForm.chatHistory.length === 2;
    const takeoutOrdersCheck = fileForm.takeoutOrders.length >= 2;
    const sharedPositionCheck = fileForm.sharedPosition.length >= 3;
    const Check =
      IDCardFrontCheck &&
      IDCardBackCheck &&
      chatHistoryCheck &&
      takeoutOrdersCheck &&
      sharedPositionCheck;
    if (!Check) ElMessage.error("提交失败o(≧口≦)o，请检查图片上传是否遗漏 ");
    return Check;
  } catch {
    ElMessage.error("提交失败o(≧口≦)o，请检查图片上传是否遗漏 ");
    return false;
  }
};
// 4.表单验证
const personRef = ref();
const parentsRef = ref();
const sellerAddRules = reactive({
  qqNumber: [
    {
      required: true,
      type: "number",
      message: "格式错误",
      trigger: ["change", "blur"],
    },
    {
      pattern: /^[1-9]{1}[0-9]{4,14}$/,
      message: "请核对qq账号是否正确",
    },
  ],
  name: [
    {
      required: true,
      message: "名字不能为空",
      trigger: "blur",
    },
    {
      pattern: /^.{0,15}$/,
      message: "字数超出限制，最大字符为15",
    },
  ],
  phoneNumber: [
    {
      required: true,
      type: "number",
      message: "格式错误",
      trigger: ["change", "blur"],
    },
    {
      pattern:
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
      message: "请核对手机号是否正确",
    },
  ],
  IDCard: [
    {
      required: true,
      type: "number",
      message: "格式错误",
      trigger: ["change", "blur"],
    },
    {
      pattern:
        /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}([0-9]|X|x)$/,
      message: "请核对手机号是否正确",
    },
  ],
  father: [
    {
      required: true,
      message: "请输入相应信息",
      trigger: ["change", "blur"],
    },
    {
      pattern: /^.{0,30}$/,
      message: "请核对信息是否正确，最大字符限制为30",
    },
  ],
  mother: [
    {
      required: true,
      message: "请输入相应信息",
      trigger: ["change", "blur"],
    },
    {
      pattern: /^.{0,30}$/,
      message: "请核对信息是否正确，最大字符限制为30",
    },
  ],
});
const fromCheck = async () => {
  const person = await new Promise((resolve, reject) => {
    personRef.value.validate((value) => {
      if (value) {
        resolve(value);
      } else {
        ElMessage.error("提交失败，请检查个人信息，上传是否遗漏 ");
        resolve(value);
      }
    });
  });
  const parents = await new Promise((resolve, reject) => {
    parentsRef.value.validate((value) => {
      if (value) {
        resolve(value);
      } else {
        ElMessage.error("提交失败，请检查紧急联系人信息，上传是否遗漏 ");
        resolve(value);
      }
    });
  });
  return { person, parents };
};
// 五、点击提交
const router = useRouter();
const submit = async () => {
  fromCheck().then(async (result) => {
    if (result.parents && result.person && imageCheck()) {
      getNowDate();
      const params = switchFormData();
      try {
        const res = await axios.post("/adminapi/seller/add", params, {
          headers: {
            // 配置相应请求头
            "Content-Type": "multipart/form-data",
          },
        });
        if (res.data.code === 1) {
          ElNotification({
            title: "提交成功",
            message: "请在资料列表查看",
            type: "success",
          });
          router.push({
            path: "/seller-manage/sellerlist",
            query: {
              date: new Date().getTime(),
            },
          });
        } else {
          ElMessage.error("提交失败" + res.data.err);
        }
      } catch (err) {
        ElMessage.error("提交失败" + err);
      }
    }
  });
};
</script>
