<template>
  <div class="header">
    <el-page-header icon="" title="员工管理">
      <template #content>
        <span class="text-Medium font-600 mr-3"> 添加员工 </span>
      </template>
    </el-page-header>
  </div>
  <div class="main" style="width: 600px">
    <el-card shadow="hover">
      <template #header>
        <div id="card-header">
          <span>员工信息表单</span>
        </div>
      </template>
      <el-form
        :inline="true"
        ref="userFormRef"
        :model="userForm"
        :rules="userFormRules"
        label-width="80px"
        class="demo-ruleForm"
        status-icon
      >
        <el-divider content-position="left">联系信息</el-divider>
        <el-form-item label="姓名" prop="name">
          <el-input
            v-model.trim="userForm.name"
            placeholder="真实姓名"
            style="width: 110px"
          />
        </el-form-item>
        <br />

        <el-form-item label="手机号" prop="phoneNumber">
          <el-input
            v-model.trim.number="userForm.phoneNumber"
            style="width: 150px"
          />
        </el-form-item>

        <el-form-item label="常用微信" prop="WeChatAccount">
          <el-input
            v-model.trim="userForm.WeChatAccount"
            style="width: 150px"
          />
        </el-form-item>

        <el-divider content-position="left">用户信息</el-divider>
        <el-form-item label="昵称" prop="petname">
          <el-input
            placeholder="趣兔XXX"
            v-model.trim="userForm.petname"
            style="width: 130px"
          />
        </el-form-item>
        <br />

        <el-form-item label="用户名" prop="username">
          <el-input
          
            placeholder="登录账号"
            v-model.trim="userForm.username"
            style="width: 130px"
          />
        </el-form-item>

        <el-form-item label="密码" prop="password">
          <el-input
            show-password
            placeholder="登录密码"
            v-model.trim="userForm.password"
            type="password"
            style="width: 130px"
          />
        </el-form-item>

        <el-form-item label="职位" prop="role">
          <el-select
            v-model="userForm.role"
            placeholder="请选择职位"
            style="width: 130px"
          >
            <el-option
              v-for="item in roleOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="性别" prop="gender">
          <el-select
            v-model="userForm.gender"
            placeholder="Select"
            style="width: 130px"
          >
            <el-option
              v-for="item in genderOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="个人简介" prop="introduction">
          <el-input
            v-model.trim="userForm.introduction"
            type="textarea"
            style="width: 300px"
            maxlength="50"
            show-word-limit
          />
        </el-form-item>

        <el-form-item label="头像" prop="avatar">
          <Upload :avatar="avatarUrl" @avatarChange="handleChange"></Upload>
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="submitForm"> 添加用户 </el-button>
    </el-card>
  </div>
</template>

<style lang="less" scoped>
.header {
  padding-top: 2px;
  margin-bottom: 10px;
}
#card-header {
  display: flex;
  justify-content: space-between;
  span {
    font-size: 20px;
    font-weight: 550;
    color: #606266;
  }
}
.el-button {
  font-size: 15px;
  position: relative;
  left: 450px;
}
</style>

<script setup>
import { ElMessage } from "element-plus";
import { ref, reactive } from "vue";
import Upload from "@/components/upload/Upload.vue";
import upload from "@/util/upload";
import router from "@/router";
const userFormRef = ref();
const userForm = reactive({
  name: null,
  phoneNumber: null,
  WeChatAccount: null,
  petname: null,
  username: null,
  password: null,
  role: null, // 1管理员，2资料管理，3活动管理，4评估主播
  gender: 0,
  introduction: null,
  file: null,
});

const userFormRules = reactive({
  name: [
    {
      required: true,
      message: "名字不能为空",
      trigger: "blur",
    },
    {
      pattern: /^.{0,10}$/,
      message: "字数超出限制",
    },
  ],
  WeChatAccount: [
    {
      required: true,
      message: "请输入个人常用微信号",
      trigger: "blur",
    },
    {
      pattern: /^.{0,15}$/,
      message: "字数超出限制",
    },
  ],
  phoneNumber: [
    {
      required: true,
      type: "number",
      message: "格式错误",
      trigger: ["change", "blur"],
    },
    {
      pattern:
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
      message: "请核对手机号是否正确",
    },
  ],
  petname: [
    {
      required: true,
      message: "昵称不能为空",
      trigger: "blur",
    },
    {
      pattern: /^.{0,10}$/,
      message: "字数超出限制",
    },
  ],
  username: [
    {
      required: true,
      message: "名字不能为空",
      trigger: "blur",
    },
    {
      pattern: /^.{0,12}$/,
      message: "账号限制12位",
    },
  ],
  password: [
    {
      required: true,
      message: "密码不能为空",
      trigger: "blur",
    },
    {
      pattern: /^.{8,15}$/,
      message: "密码长度为8~15位",
    },
  ],
  gender: [{ required: true, message: "选择性别", trigger: "blur" }],
  role: [{ required: true, message: "选择职位", trigger: "blur" }],
});

const roleOptions = [
  { label: "管理员", value: 1 },
  { label: "资料审核", value: 2 },
  { label: "活动管理", value: 3 },
  { label: "评估主播", value: 4 },
];

const genderOptions = [
  {
    label: "保密",
    value: 0,
  },
  {
    label: "男",
    value: 1,
  },
  {
    label: "女",
    value: 2,
  },
];
const avatarUrl = ref();
// 每次选择完图片回调
const handleChange = (file) => {
  // 调用原生dom 将file.raw转为url
  avatarUrl.value = URL.createObjectURL(file);
  userForm.file = file;
};

const submitForm = () => {
  userFormRef.value.validate(async (valid) => {
    if (valid) {
      try {
        const res = await upload("/adminapi/user/add", userForm);
        if (res.data.code === 1) {
          ElMessage({
            message: "添加成功",
            type: "success",
          });
          router.push("/user-manage/userlist");
        } else {
          ElMessage({
            message: "添加失败，该用户名已存在",
            type: "warning",
          });
        }
      } catch (err) {
        ElMessage.error("系统错误，添加失败，" + err);
      }
    }
  });
};
</script>
