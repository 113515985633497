// 图片压缩函数
const compressImage = (file) => {
  // 大小判断
  if (file.size > 3 * 1024 * 1024) {
    // 大于3MB的图片需要压缩
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          const maxSize = 3 * 1024 * 1024; // 3MB
          let width = img.width;
          let height = img.height;
          // 压缩算法，如果超过最大大小则等比例缩小
          let ratio = 1;
          if (file.size > maxSize) {
            ratio = Math.sqrt(maxSize / file.size);
            width *= ratio;
            height *= ratio;
          }
          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);
          canvas.toBlob(
            (blob) => {
              resolve(
                new File([blob], file.name, {
                  type: "image/jpeg",
                })
              );
            },
            "image/jpeg",
            0.9
          );
        };
      };
    });
  } else {
    return file;
  }
};

export default compressImage;