<template>
  <div class="main">
    <div class="header">
      <el-page-header icon="" title="趣兔网游后台">
        <template #content>
          <span class="text-Medium font-600 mr-3"> 首页 </span>
        </template>
      </el-page-header>
    </div>

    <div>
      <el-card shadow="always">
        <el-row>
          <el-col :span="4">
            <el-avatar :size="100" :src="avatarUrl" />
          </el-col>
          <el-col :span="20">
            <h3 style="line-height: 100px">
              {{ store.state.userInfo.petname }}，欢迎回来
            </h3>
          </el-col>
        </el-row>
      </el-card>

      <el-card shadow="always">
        <Statistic></Statistic>
      </el-card>

      <el-card shadow="always">
        <h3>公司通告</h3>
        <el-carousel :interval="4000" type="card" height="200px">
          <el-carousel-item v-for="item in 6" :key="item">
            <h3 text="2xl" justify="center">{{ item }}</h3>
          </el-carousel-item>
        </el-carousel>
      </el-card>
    </div>
  </div>
</template>

<style lang="less" scoped>
.header {
  padding-top: 2px;
  margin-bottom: 10px;
}

.el-card {
  margin-top: 20px;
}

.el-carousel__item h3 {
  color: #475669;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
<script setup>
import Statistic from "@/components/statistic/Statistic.vue";
import { useStore } from "vuex";
import { computed } from "vue";
const store = useStore();
const avatarUrl = computed(() =>
  store.state.userInfo.avatar
    ? store.state.userInfo.avatar
    : `https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png`
);
</script>
