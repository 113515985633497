<template>
  <div id="header">
    <el-page-header icon="" title="点券活动">
      <template #content>
        <span class="text-Medium font-600 mr-3"> 点券活动管理系统 </span>
      </template>
    </el-page-header>
  </div>
  <div>
    <el-card shadow="hover" style="max-width: 1100px">
      <template #header>
        <div id="card-header">
          <span>点券活动业务列表</span>
          <el-popover placement="bottom-end" :width="400" trigger="click">
            <template #reference>
              <el-button
                :icon="Edit"
                id="headerButton"
                @click="dialogTableVisible = true"
                >添加活动
              </el-button>
            </template>
            <span>活动添加</span>
            <el-form
              label-position="top"
              label-width="auto"
              :model="activityAddForm"
              ref="activityAddFormRef"
              :rules="activityAddFormRules"
            >
              <el-form-item prop="title" label="标题">
                <el-input
                  v-model="activityAddForm.title"
                  maxlength="10"
                  show-word-limit
                  style="width: 260px"
                />
              </el-form-item>
              <el-form-item prop="details" label="详情">
                <el-input
                  v-model="activityAddForm.details"
                  type="textarea"
                  style="width: 260px"
                />
              </el-form-item>
              <el-row style="width: 320px">
                <el-col :span="12">
                  <el-form-item prop="plate" label="板块">
                    <el-select
                      v-model="activityAddForm.plate"
                      placeholder="业务类型"
                      style="width: 120px"
                      @click.stop
                    >
                      <el-option
                        v-for="item in plateOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="price" label="价格">
                    <el-input
                      v-model.number="activityAddForm.price"
                      style="width: 100px"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- 图片上传  -->
              <el-text id="uploadTitle"
                >主图：
                <el-text class="mx-1" size="small"
                  >尺寸（1:1）</el-text
                ></el-text
              >
              <div id="upload">
                <el-upload
                  class="avatar-uploader"
                  :show-file-list="false"
                  :auto-upload="false"
                  accept=".jpg,.png"
                  :on-change="handleUpload"
                  action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15"
                >
                  <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                  <el-icon v-else class="avatar-uploader-icon"
                    ><Plus
                  /></el-icon>
                </el-upload>
              </div>
              <el-button id="addBtn" @click="addBtn" type="primary"
                >添加</el-button
              >
            </el-form>
          </el-popover>
        </div>
      </template>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column
          prop="date"
          label="上架时间"
          header-align="center"
          align="center"
          width="110"
        />
        <el-table-column
          prop="plate"
          label="板块"
          header-align="center"
          align="center"
          width="100"
        />
        <el-table-column prop="title" label="标题" width="200" />
        <el-table-column prop="details" label="详情" width="210" />
        <el-table-column
          label="图片"
          width="70"
          header-align="center"
          align="center"
        >
          <template #default="scope">
            <el-button type="primary" @click="handleView(scope.row.image)" link
              >查看</el-button
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="price"
          label="价格"
          header-align="center"
          align="center"
          width="100"
        />
        <el-table-column
          fixed="right"
          header-align="center"
          align="center"
          width="120"
        >
          <template #default="scope">
            <el-popconfirm
              title="你确定要删除吗？"
              @confirm="activityDel(scope.row._id)"
              confirm-button-text="确定"
              cancel-button-text="取消"
            >
              <template #reference>
                <el-button type="danger" :icon="Delete" circle />
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <!-- 图片查看器 -->
    <div>
      <el-image-viewer
        v-if="showViewer"
        @close="closeViewer"
        :url-list="srcList"
        :hide-on-click-modal="true"
      />
    </div>
  </div>
</template>

<style lang="less" scoped>
#header {
  padding-top: 2px;
  margin-bottom: 10px;
}
#card-header span {
  font-size: 20px;
  font-weight: 550;
  color: #606266;
}
#addBtn {
  position: relative;
  left: 300px;
}
#uploadTitle {
  margin-bottom: 15px;
}
::v-deep.avatar-uploader .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
::v-deep.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

::v-deep.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

::v-deep.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}

#upload {
  margin-top: 10px;
  width: 100px;
}
#card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#headerButton {
  color: #0099ff;
  height: 40px;
  font-size: 16px;
  font-weight: 600;
}
::v-deep .el-image-viewer__canvas .el-image-viewer__img {
  width: 80%;
  height: 80%;
  object-fit: contain;
}
</style>

<script setup>
import { ref, reactive, onMounted } from "vue";
import { Edit, Delete } from "@element-plus/icons-vue";
import { Plus } from "@element-plus/icons-vue";
import { ElMessage, ElNotification } from "element-plus";
import upload from "@/util/upload";
import axios from "axios";
// 表单
const tableData = ref([]);

// 列表渲染
onMounted(() => {
  getTableData();
});

const getTableData = async () => {
  try {
    const res = await axios.get("/adminapi/activity/list");
    if (res.data.code === 1) {
      tableData.value = res.data.data;
    } else {
      ElMessage({
        showClose: true,
        message: res.data.err,
        type: "error",
      });
    }
  } catch (err) {
    ElMessage({
      showClose: true,
      message: err,
      type: "error",
    });
  }
};

// 图片查看
const showViewer = ref(false);
const srcList = ref([]);
const handleView = (filename) => {
  srcList.value.push(filename);
  showViewer.value = true;
};
const closeViewer = () => {
  srcList.value.length = 0;
  showViewer.value = false;
};
// 活动添加
const activityAddForm = reactive({
  date: null,
  title: "",
  details: "",
  plate: "",
  price: null,
  file: null,
});
// 活动添加表单校验
const activityAddFormRef = ref();
const activityAddFormRules = reactive({
  title: [
    {
      required: true,
      message: "标题不能为空",
      trigger: "blur",
    },
  ],
  details: [
    {
      required: true,
      message: "标题不能为空",
      trigger: "blur",
    },
  ],
  price: [
    {
      required: true,
      type: "number",
      message: "格式错误，价格应为纯数字",
      trigger: ["change", "blur"],
    },
  ],
  plate: [
    {
      required: true,
      message: "请选择上架板块",
      trigger: ["change", "blur"],
    },
  ],
});

// 业务板块选项
const plateOptions = reactive([
  { value: "CF点券", label: "CF点券" },
  { value: "召集币", label: "召集币" },
  { value: "被盗模型", label: "被盗模型" },
  { value: "等级", label: "等级" },
]);
// 图片反显
const imageUrl = ref("");
const handleUpload = (file) => {
  if (beforeUpload(file)) {
    imageUrl.value = URL.createObjectURL(file.raw);
    activityAddForm.file = file.raw;
  }
};
// 判断图片是否上传
const ifFile = () => {
  if (!activityAddForm.file) {
    ElNotification({
      title: "提交失败",
      message: "请上传账号图片",
      type: "error",
    });
    return false;
  }
  return true;
};
// 点击删除
const activityDel = async (_id) => {
  try {
    const res = await axios.delete(`/adminapi/activity/delete/${_id}`);
    if (res.data.code === 1) {
      ElMessage.error("删除成功");
      getTableData();
    }
  } catch (err) {
    ElMessage.error("删除失败，请联系网站管理员处理" + err);
  }
};
// 点击上传
const addBtn = () => {
  activityAddFormRef.value.validate(async (value) => {
    if (value && ifFile()) {
      activityAddForm.date = getNowDate();
      try {
        const res = await upload("/adminapi/activity/add", activityAddForm);
        if (res.data.code === 1) {
          ElNotification({
            title: "添加成功",
            message: `已将【${activityAddForm.title}】添加至${activityAddForm.plate}板块`,
            type: "success",
          });
          getTableData();
        }
      } catch {
        ElMessage.error("上传失败，请联系网站管理员处理" + err);
      }
    }
  });
};

// 上传文件前 判断
const beforeUpload = (file) => {
  // 类型
  const isJPG = file.raw.type === "image/jpeg";
  const isPNG = file.raw.type === "image/png";
  // 大小
  const isLt3M = file.raw.size / 1024 / 1024 < 3;
  if (!(isJPG || isPNG)) {
    ElNotification({
      title: "提交失败",
      message: "上传类型错误",
      type: "error",
    });
  }
  if (!isLt3M) {
    ElNotification({
      title: "提交失败",
      message: "上传文件需小于3MB",
      type: "error",
    });
  }
  return (isJPG || isPNG) && isLt3M;
};

// 当前时间
const getNowDate = () => {
  const date = new Date();
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let strDate = date.getDate();
  if (month >= 1 && month < 9) month = "0" + month;
  if (strDate >= 1 && strDate <= 9) strDate = "0" + strDate;
  return year + "-" + month + "-" + strDate;
};
</script>
