// mainbox动态子路由
import Home from "@/views/home/Home.vue";
import Center from "@/views/center/Center.vue";
// 账号管理
import CFGameListVue from "@/views/game-manage/CF/CF-GameList.vue";
import CFGameAddVue from "@/views/game-manage/CF/CF-GameAdd.vue";
import ValorantGameAddVue from "@/views/game-manage/VALORANT/VALORANT-GameAdd.vue";
import ValorantGameListVue from "@/views/game-manage/VALORANT/VALORANT-GameList.vue";
// 点券活动
import ActivityVue from "@/views/activity-manage/Activity.vue";

import UserListVue from "@/views/User-manage/UserList.vue";
import UserAddVue from "@/views/User-manage/UserAdd.vue";
import SellerAdd from "@/views/seller-manage/SellerAdd.vue";
import SellerList from "@/views/seller-manage/SellerList.vue";
import KefuCheck from "@/views/kefucheck-manage/KefuCheck.vue";
import Operationlog from "@/views/user-operationlog/Operationlog.vue";

const routes = [
  {
    path: "/index",
    component: Home,
  },
  {
    path: "/center",
    component: Center,
  },
  {
    path: "/game-manage/cf/gamelist",
    component: CFGameListVue,
  },
  {
    path: "/game-manage/cf/gameadd",
    component: CFGameAddVue,
  },
  {
    path: "/game-manage/valorant/gameList",
    component: ValorantGameListVue,
  },
  {
    path: "/game-manage/valorant/gameadd",
    component: ValorantGameAddVue,
  },
  {
    path: "/activity-manage",
    component: ActivityVue,
    
  },

  {
    path: "/user-manage/userlist",
    component: UserListVue,
    requirePower: [1],
  },
  {
    path: "/user-manage/useradd",
    component: UserAddVue,
    requirePower: [1],
  },
  {
    path: "/seller-manage/selleradd",
    component: SellerAdd,
    requirePower: [1, 2],
  },
  {
    path: "/seller-manage/sellerlist",
    component: SellerList,
    requirePower: [1, 2],
  },
  {
    path: "/kefucheck",
    component: KefuCheck,
    requirePower: [1],
  },
  {
    path: "/user-operationlog",
    component:Operationlog
  },
  {
    path: "/",
    redirect: "/index",
  },
];
export default routes;
