import { createStore } from "vuex";
//导入persistedstate持久化部分数据
import createPersistedState from "vuex-persistedstate";
export default createStore({
  state: {
    isGetterRouter: false,
    isCollapse: false,
    userInfo: {},
    gameEditId: "",
    isGameEdit: false,
    kefuEdit: "",
    userId: null,
  },
  getters: {},
  mutations: {
    // state为原始声明数据 value为传参
    changeGetterRouter(state, value) {
      state.isGetterRouter = value;
    },
    changeisCollapse(state) {
      state.isCollapse = !state.isCollapse;
    },
    changeUserInfo(state, value) {
      state.userInfo = {
        ...state.userInfo,
        ...value,
      };
    },

    clearUserInfo(state) {
      state.userInfo = {};
    },
    changeGameEdit(state) {
      state.isGameEdit = !state.isGameEdit;
    },
    changKefuEdit(state, value) {
      state.kefuEdit = value;
    },
  },
  actions: {},
  modules: {},
  plugins: [
    createPersistedState({
      paths: ["userInfo"], // paths 控制持久化（刷新后数据是否刷新）
    }),
  ],
});
