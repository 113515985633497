<template>
  <div class="bgc">
    <div class="formContainer">
      <el-row>
        <el-col id="login_left_bgc" :span="14"><img src="../assets/loginleft.png" alt="" /></el-col>
        <el-col :span="10">
          <div id="login_right_bgc">
            <div id="login">
              <p>趣兔网游后台</p>
              <el-form
                :model="loginForm"
				
                class="loginFrom"
              >
                <el-form-item>
                  <el-input
                    :prefix-icon="User"
                    v-model.trim="loginForm.username"
                    autocomplete="off"
                    style="width: 240px"
                    clearable
                  />
                </el-form-item>
                <el-form-item>
                  <el-input
                    :prefix-icon="Lock"
                    style="width: 240px"
                    v-model.trim="loginForm.password"
                    type="password"
                    autocomplete="off"
                    @keyup.enter="submitForm"
                    show-password
                  />
                </el-form-item>
				 <el-form-item class="checkbox-group">
				      <el-checkbox-group v-model="loginForm.radio">
				        <el-checkbox value='remember' name="type">
				          记住密码
				        </el-checkbox>
				      </el-checkbox-group>
				    </el-form-item>
                <el-form-item>
                  <el-button v-loading="loading" @click="submitForm">
                    登 录
                  </el-button>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<style lang="less" scoped>
.bgc {
  background-image: linear-gradient(100deg, #4facfe 0%, #00f2fe 100%);
  color: #ffffff;
  width: 100%;
  height:100vh;
  line-height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;

}
.formContainer {
  width: 1000px;
  height: 450px;
  position: fixed;
  left: 50%;
  top: 60%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  text-align: center;
  border-radius: 5px;
  backdrop-filter: blur(30px);
  box-shadow: #fdfdfd 0px 0.1px 15px 1px;

}
::v-deep .el-form-item__label {
  color: #ffffff;
}
.checkbox-group{
	padding-left: 160px;
}
.el-button {
  width: 200px;
  height: 38px;
  margin-top: 20px;
  background-color: #0084ff;
  border-radius: 50px;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
}
.el-button:hover{
	background-color: #0aa1ff;
	color: #fff;
}
#login_left_bgc {
 img{
	 width: 100%;
	 height: 450px;
	 border-top-left-radius: 5px;
	 border-bottom-left-radius: 5px;
 }
}
#login_right_bgc {
  height: 400px;
  background: #ffffff;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  .el-form{
	  display: flex;
	  flex-direction: column;
	  align-items: center;
  }
  img {
    width: 28%;
  }
  p {
    margin: 50px 0px 30px 0px;
    color: #0084ff;
	font-weight: 500;
    font-size: 30px;
  }

  #login {
    margin-top: 40px;
  }
  ::v-deep .el-input__wrapper {
    background-color: transparent;
    border-bottom: 2px solid; // 边框宽度 实线 颜色
    border-radius: 15px; // 边角-圆角半径
  }
  ::v-deep .el-input__inner {
    height: 35px; // 高度
    line-height: 35px; // 高度
    font-size: 15px;
    font-weight: 600;
    color: #0084ff; // 内容字体颜色

    background-color: transparent;
  }
  
  
  ::v-deep .el-input__prefix-inner svg {
    color: #0084ff !important;
  }
}

::v-deep .el-loading-mask {
  border-radius: 15px; // 边角-圆角半径
  ::v-deep svg.circular {
	color: #0084ff;
    width: 80px;
  }
}


</style>

<script setup>
import axios from "axios";
import { ElMessage } from "element-plus";
import { onMounted, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import store from "@/store";
import { User, Lock } from "@element-plus/icons-vue";


// 表单绑定响应式对象
const loginForm = reactive({
  username: "",
  password: "",
  radio:[]
});
//读取本地密码
   onMounted(() => {  
	   if(localStorage.getItem('username')){
		   loginForm.radio[0] = 'remember'
		   loginForm.username = localStorage.getItem('username')
		   loginForm.password = localStorage.getItem('password')
	   }
   
    });  
// 提交表单函数
const router = useRouter();
const loading = ref(false);
const submitForm = () => {
	console.log(loginForm.radio[0])
  if (loginForm.username) {
    axios
      .post("/adminapi/user/login", loginForm)
      .then((res) => {
        if (res.data.code === 1) {
          loading.value = true;
          // 将用户基础数据传入store
          store.commit("changeUserInfo", res.data.data);
          // 重新写入路由
          store.commit("changeGetterRouter", false);
          localStorage.setItem("token", res.headers.authorization);
		  if(loginForm.radio[0] == 'remember'){
			   localStorage.setItem('username', loginForm.username);  
			   localStorage.setItem('password', loginForm.password);
		  }else{
			  localStorage.removeItem('username');  
			  localStorage.removeItem('password');
		  }
          setTimeout(() => {
            ElMessage({
              message: `你好，${res.data.data.petname}`,
              type: "success",
            });
            router.push("/index");
          }, 300);
        } else if (res.data.code === -1) {
          ElMessage.error("登录失败，用户名或密码错误");
        } else{
          ElMessage.error("后端发生错误" + res.data.err);
        }
      })
      .catch((err) => {
        ElMessage.error("系统错误" + err);
      });
  } else {
    ElMessage.error("请输入用户名或密码");
  }
};
</script>
