<template>
  <div class="header">
    <el-page-header icon="" title="趣兔网游后台">
      <template #content>
        <span class="text-Medium font-600 mr-3"> 个人中心 </span>
      </template>
    </el-page-header>
  </div>

  <div class="main" style="width: 1100px">
    <el-row :gutter="24">
      <el-col :span="8">
        <el-card style="max-width: 500px" class="box-card" shadow="hover">
          <el-avatar :size="100" :src="avatarUrl" />
          <h2>{{ store.state.userInfo.petname }}</h2>
          <h5>{{ role }}</h5>
        </el-card>
      </el-col>

      <el-col :span="16">
        <el-card shadow="hover" style="max-width: 550px">
          <template #header>
            <div class="card-header">
              <span>个人信息</span>
            </div>
          </template>

          <el-form
            :inline="true"
            ref="userFormRef"
            :model="userForm"
            :rules="userFormRules"
            label-width="auto"
            class="demo-ruleForm"
            status-icon
          >
            <el-divider content-position="left">联系信息</el-divider>

            <el-form-item label="姓名" prop="name">
              <el-input
                disabled
                v-model.trim="userForm.name"
                style="width: 100px"
              />
            </el-form-item>
            <br />

            <el-form-item label="手机号" prop="phoneNumber">
              <el-input
                v-model.trim.number="userForm.phoneNumber"
                style="width: 130px"
              />
            </el-form-item>

            <el-form-item label="常用微信" prop="WeChatAccount">
              <el-input
                v-model.trim="userForm.WeChatAccount"
                style="width: 130px"
              />
            </el-form-item>
            <el-divider content-position="left">用户信息</el-divider>
            <el-form-item label="昵称" prop="petname" style="width: 200px">
              <el-input v-model="userForm.petname" />
            </el-form-item>

            <el-form-item label="性别" prop="gender">
              <el-select
                v-model="userForm.gender"
                placeholder="Select"
                style="width: 130px"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="用户名" prop="username" style="width: 200px">
              <el-input disabled v-model="userForm.username" />
            </el-form-item>
            <el-form-item label="密码" prop="password">
              <el-input
                show-password
                v-model.trim="userForm.password"
                type="password"
                style="width: 130px"
              />
            </el-form-item>

            <el-form-item
              label="个人简介"
              prop="introduction"
              style="width: 430px"
            >
              <el-input
                v-model="userForm.introduction"
                type="textarea"
                maxlength="50"
                show-word-limit
              />
            </el-form-item>

            <el-form-item label="头像" prop="avatar">
              <Upload
                :avatar="userForm.avatar"
                @avatarChange="handleChange"
              ></Upload>
            </el-form-item>

            <el-form-item class="genxin">
              <el-button type="primary" @click="submitForm"> 更新 </el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<style lang="less" scoped>
.header {
  padding-top: 2px;
  margin-bottom: 10px;
}
.main {
  margin-top: 20px;
  .box-card {
    text-align: center;
  }
  h2 {
    letter-spacing: 3px;
  }
}
.genxin {
  position: relative;
  left: 79px;
}
</style>

<script setup>
import Upload from "@/components/upload/Upload.vue";
import { useStore } from "vuex";
import { computed, ref, reactive } from "vue";
import { ElMessage } from "element-plus";
import upload from "@/util/upload";

const store = useStore();
const role = computed(() => {
  if (store.state.userInfo.role === 1) {
    return "管理员";
  } else if (store.state.userInfo.role === 2) {
    return "客服";
  } else if (store.state.userInfo.role === 3) {
    return "活动管理";
  } else {
    return "评估主播";
  }
});
const avatarUrl = computed(() =>
  store.state.userInfo.avatar
    ? store.state.userInfo.avatar
    : `https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png`
);

const userFormRef = ref();
const {
  username,
  petname,
  gender,
  introduction,
  avatar,
  name,
  phoneNumber,
  WeChatAccount,
} = store.state.userInfo;

const userForm = reactive({
  name,
  phoneNumber: Number(phoneNumber),
  WeChatAccount,
  username,
  petname,
  gender,
  introduction,
  avatar,
  password: null,
  file: null,
});

const userFormRules = reactive({
  name: [
    {
      required: true,
      message: "名字不能为空",
      trigger: "blur",
    },
    {
      pattern: /^.{0,10}$/,
      message: "字数超出限制",
    },
  ],
  WeChatAccount: [
    {
      required: true,
      message: "请输入个人常用微信号",
      trigger: "blur",
    },
    {
      pattern: /^.{0,15}$/,
      message: "字数超出限制",
    },
  ],
  phoneNumber: [
    {
      required: true,
      type: "number",
      message: "格式错误",
      trigger: ["change", "blur"],
    },
    {
      pattern:
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
      message: "请核对手机号是否正确",
    },
  ],
  petname: [
    {
      required: true,
      message: "昵称不能为空",
      trigger: "blur",
    },
    {
      pattern: /^.{0,10}$/,
      message: "字数超出限制",
    },
  ],
  username: [
    {
      required: true,
      message: "名字不能为空",
      trigger: "blur",
    },
    {
      pattern: /^.{0,12}$/,
      message: "账号限制12位",
    },
  ],
  password: [
    {
      required: false,
      message: "密码不能为空",
      trigger: "blur",
    },
    {
      pattern: /^.{0,15}$/,
      message: "密码应为15位以内",
    },
  ],
  gender: [{ required: true, message: "选择性别", trigger: "blur" }],
});

const options = [
  {
    label: "保密",
    value: 0,
  },
  {
    label: "男",
    value: 1,
  },
  {
    label: "女",
    value: 2,
  },
];
const handleChange = (file) => {
  userForm.avatar = URL.createObjectURL(file);
  userForm.file = file;
};

// 更新提交
const submitForm = () => {
  userFormRef.value.validate(async (valid) => {
    if (valid) {
      try {
        const res = await upload(
          "/adminapi/user/upload",
          removeNullProperties(userForm)
        );
        if (res.data.code === 1) {
          store.commit("changeUserInfo", res.data.data);
          ElMessage({
            message: "更新成功",
            type: "success",
          });
        } else {
          ElMessage.error("资料更新失败，" + res.data.err);
        }
      } catch (err) {
        ElMessage.error("资料更新失败" + err);
      }
    }
  });
};

// 删除为null的字段
function removeNullProperties(obj) {
  // 使用一个空对象来存储非null的属性
  const newObj = {};

  // 遍历原始对象
  for (const key in obj) {
    // 检查属性是否属于对象自身，并且其值不是null
    if (obj.hasOwnProperty(key) && obj[key] !== null) {
      newObj[key] = obj[key];
    }
  }
  // 返回一个新的reactive对象
  return reactive(newObj);
}
</script>
