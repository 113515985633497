import { createRouter, createWebHashHistory} from "vue-router";
import Login from "@/views/Login.vue";
import MainBox from "@/views/MainBox.vue";
import MainBoxSonConfig from "./MainboxSon/config";
import store from "../store/index";
import NotFoundVue from "@/views/NotFound.vue";

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/mainbox",
    name: "mainbox",
    component: MainBox,
    //mainbox嵌套子路由，后面根据权限动态添加
  },
  {
    path: "/:pathMatch(.*)*",
    component: NotFoundVue,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// 动态给mainbox添加子路由
const ConfigRouter = () => {
  if (!router.hasRoute("mainbox")) {
    router.addRoute({
      path: "/mainbox",
      name: "mainbox",
      component: MainBox,
      // mainbox嵌套子路由，后面根据权限动态添加
    });
  }
  MainBoxSonConfig.forEach((item) => {
    checkPermission(item) && router.addRoute("mainbox", item);
  });
};

const checkPermission = (item) => {
  if (item.requirePower) {
    return item.requirePower.indexOf(store.state.userInfo.role) > -1;
  }
  return true;
};
// 路由守卫（每次跳转前自动执行） to：跳转路由 next：放行
router.beforeEach((to, from, next) => {
  if (to.name === "login") {
    next();
  } else {
    // 如果已授权（已经登陆） next({ path:to.fullPath })
    // 未授权，重定向到login
    // 注意：beforeEach是全局守卫（死循环） 在判断第一次授权跳转后 关闭二次判断
    if (!localStorage.getItem("token")) {
      next({
        path: "/login",
      });
    } else {
      // 只对第一次判断
      if (!store.state.isGetterRouter) {
        // 删除所有嵌套路由（二次登录）
        router.removeRoute("mainbox");
        // 添加子路由
        ConfigRouter();
        // 子路由为守卫创建后创建 跳转需要next重新写入路径
        next({
          path: to.fullPath,
        });
        store.commit("changeGetterRouter", true);
      } else {
        // 第二次判断才会进入else 不需要对next重新写入
        next();
      }
    }
  }
});

export default router;
