<template>
  <div>
    <el-header>
      <div class="left">
        <span class="qutuico"> </span>
        <span class="title"> 趣兔后台管理系统</span>
      </div>
      <div class="right">
        <span>欢迎 {{ store.state.userInfo.username }} 回来</span>
        <el-dropdown>
          <span class="el-dropdown-link">
            <el-icon :size="30" color="white"><User /></el-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="handleCenter"
                >个人中心</el-dropdown-item
              >
              <el-dropdown-item @click="handleExit">退出</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </el-header>
  </div>
</template>
<style lang="less" scoped>
.el-header {
  background-image: linear-gradient(to right, #4facfe 0%, #00f2fe 100%);
  color: #ffffff;
  width: 100%;
  height: 60px;
  line-height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 25px;
  box-shadow:
    rgba(0, 102, 255, 0.4) 5px 5px,
    rgba(46, 153, 240, 0.534) 10px 10px,
    rgba(71, 184, 236, 0.5) 15px 15px,
    rgba(100, 164, 247, 0.1) 20px 20px;
  .left,
  .right {
    display: flex;
    align-items: center;
  }
  .qutuico {
    height: 50px;
    width: 50px;
    background-image: url(../../assets/qutu_1.png);
    background-repeat: no-repeat;
    background-size: cover;
  }
  .title {
    margin-left: 5px;
  }
  .el-dropdown-link {
    outline: none;
  }
}
</style>

<script setup>
import { useRouter } from "vue-router";
import { User } from "@element-plus/icons-vue";
import { useStore } from "vuex";
const store = useStore();
const router = useRouter();
const handleCenter = () => {
  router.push("/center");
};

const handleExit = () => {
  router.push("/login");
  localStorage.removeItem("token");
  store.commit("clearUserInfo");
};
</script>
