<template>
  <el-row :gutter="16" v-loading="loading">
    <el-col :span="8">
      <div class="statistic-card">
        <el-statistic :value="visitsToday">
          <template #title>
            <div style="display: inline-flex; align-items: center">
              今日访问总量
              <el-tooltip
                effect="dark"
                content="截至当前时刻网站用户访问总量"
                placement="top"
              >
                <el-icon style="margin-left: 4px" :size="12">
                  <Warning />
                </el-icon>
              </el-tooltip>
            </div>
          </template>
        </el-statistic>
        <div class="statistic-footer">
          <div class="footer-item">
            <span>对比昨日</span>
            <span
              :class="{
                red: visitsComparedYesterday < 0,
                green: visitsComparedYesterday > 0,
              }"
            >
              {{ visitsComparedYesterday }}%
              <el-icon v-if="visitsComparedYesterday > 0">
                <CaretTop />
              </el-icon>
              <el-icon v-else>
                <CaretBottom />
              </el-icon>
            </span>
          </div>
        </div>
      </div>
    </el-col>
    <el-col :span="8">
      <div class="statistic-card">
        <el-statistic :value="durationToday">
          <template #title>
            <div style="display: inline-flex; align-items: center">
              今日平均访问时长
              <el-tooltip
                effect="dark"
                content="今日选号网用户平均访问时长"
                placement="top"
              >
                <el-icon style="margin-left: 4px" :size="12">
                  <Warning />
                </el-icon>
              </el-tooltip>
            </div>
          </template>
        </el-statistic>
        <div class="statistic-footer">
          <div class="footer-item">
            <span>对比昨日</span>
            <span
              :class="{
                red: durationComparedToday < 0,
                green: durationComparedToday > 0,
              }"
            >
              {{ durationComparedToday }}%
              <el-icon v-if="durationComparedToday > 0">
                <CaretTop />
              </el-icon>
              <el-icon v-else>
                <CaretBottom />
              </el-icon>
            </span>
          </div>
        </div>
      </div>
    </el-col>

    <el-col :span="8">
      <div class="statistic-card">
        <el-statistic
          :value="isShelvesAccountNumber"
          :value-style="{ fontSize: '45px', fontWeight: '550', color: 'red' }"
        >
          <template #title>
            <div
              style="display: inline-flex; font-size: 15px; align-items: center"
            >
              上架中的账号数量
            </div>
          </template>
        </el-statistic>
      </div>
    </el-col>
  </el-row>
</template>

<style scoped>
:global(h2#card-usage ~ .example .example-showcase) {
  background-color: var(--el-fill-color) !important;
}

.el-statistic {
  --el-statistic-content-font-size: 28px;
}

.statistic-card {
  height: 100%;
  padding: 20px;
  border-radius: 4px;
  background-color: var(--el-bg-color-overlay);
}

.statistic-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  font-size: 12px;
  color: var(--el-text-color-regular);
  margin-top: 16px;
}

.statistic-footer .footer-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.statistic-footer .footer-item span:last-child {
  display: inline-flex;
  align-items: center;
  margin-left: 4px;
}

.green {
  color: var(--el-color-success);
}
.red {
  color: var(--el-color-error);
}
</style>

<script setup>
import { CaretBottom, CaretTop, Warning } from "@element-plus/icons-vue";
import { ElNotification } from "element-plus";
import axios from "axios";
import { onMounted, ref } from "vue";

const baiduOpenApi = {
  access_token:
    "121.3bb6db0f75211b872902eeee62c2be1c.Y7mLimLe2VF7IxyDfyuFsdUzlCnoeOqJE506KOw.Pul6sg",
  site_id: 20555049,
};
onMounted(() => {
  getBaseData();
  getIsShelvesAccountNumber();
});

// 上架中的账号
const isShelvesAccountNumber = ref(990);

const getIsShelvesAccountNumber = async () => {
  const res = await axios.get("/adminapi/game/cf/list/length");
  isShelvesAccountNumber.value = res.data.data;
};

// 今日网站访问总量&&平均访问时长
const visitsToday = ref();
const visitsComparedYesterday = ref();
const durationToday = ref();
const durationComparedToday = ref();

const loading = ref(true);
const getBaseData = async () => {
  // 获取当前日期
  let today = new Date();

  // 获取昨天的日期
  let yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);

  try {
    const visitsTodayRes = await axios.get(
      `/report/getData?access_token=${baiduOpenApi.access_token}&site_id=${baiduOpenApi.site_id}&method=overview/getTimeTrendRpt&start_date=${formatDate(yesterday)}&end_date=${formatDate(today)}&metrics=pv_count,visitor_count,ip_count,avg_visit_time`
    );
    // 访问量
    visitsToday.value = visitsTodayRes.data.result.items[1][1][0];
    let visitsYesterday = visitsTodayRes.data.result.items[1][0][0];
    visitsComparedYesterday.value = percentageChange(
      visitsYesterday,
      visitsToday.value
    );
    // 时长
    durationToday.value = visitsTodayRes.data.result.items[1][1][3];
    let durationYesterday = visitsTodayRes.data.result.items[1][0][3];
    durationComparedToday.value = percentageChange(
      durationYesterday,
      durationToday.value
    );
    durationToday.value = convertSecondsToMinutes(durationToday.value);
    loading.value = false;
  } catch (err) {
    ElNotification({
      title: "访问量获取失败",
      message: "百度统计API过期",
      type: "warning",
    });
  }
};

// 格式化日期为 YYYYMMDD 格式
const formatDate = (date) => {
  let year = date.getFullYear().toString().padStart(4, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0"); // getMonth() 返回的是 0-11
  let day = date.getDate().toString().padStart(2, "0");
  return year + month + day;
};

// 计算增加百分比
const percentageChange = (yesterday, today) => {
  if (yesterday === 0 || today === 0) {
    // 防止除以零的错误
    return 0;
  }
  let percentage = ((today - yesterday) / yesterday) * 100;
  return percentage.toFixed(2); // 返回保留两位小数的百分比
};

// 将秒转换为分钟
function convertSecondsToMinutes(seconds) {
  let minutes = Math.floor(seconds / 60); // 计算完整的分钟数
  let remainingSeconds = seconds % 60; // 计算剩余的秒数
  return `${minutes}分${remainingSeconds}秒`;
}
</script>
