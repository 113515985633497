<template>
  <div class="header">
    <el-page-header icon="" title="企业门户管理">
      <template #content>
        <span class="text-Medium font-600 mr-3"> 客服验证系统 </span>
      </template>
    </el-page-header>
  </div>
  <div class="main">
    <el-row style="width: 1150px">
      <!-- 客服列表 -->
      <el-col :span="12">
        <el-card shadow="hover" style="width: 550px">
          <template #header>
            <div class="card-header">
              <span>客服列表</span>
            </div>
          </template>
          <el-table :data="kefuList" style="width: 550px">
            <el-table-column
              prop="name"
              label="姓名"
              align="center"
              width="120"
            />
            <el-table-column prop="accountList" label="账号" width="260" />
            <!-- 编辑 -->
            <el-table-column width="130">
              <template #header>
                <el-input
                  v-model.trim.enter="search"
                  size="small"
                  @keyup.enter="kefuSearch"
                  :suffix-icon="Search"
                  placeholder="搜索"
                />
              </template>
              <template #default="scope">
                <!-- 编辑气泡框 -->
                <el-popover
                  placement="right"
                  :title="scope.row.name"
                  trigger="click"
                  :width="320"
                  :visible="visible"
                >
                  <template #reference>
                    <el-button
                      :icon="Edit"
                      type="primary"
                      @click="kefuEdit(scope.row)"
                      plain
                      circle
                    />
                  </template>
                  <KefuCheeckEdit></KefuCheeckEdit>
                </el-popover>

                <!-- 删除 -->
                <el-popconfirm
                  title="你确定要删除吗？"
                  @confirm="deleteKefu(scope.row)"
                >
                  <template #reference>
                    <el-button type="danger" :icon="Delete" circle />
                  </template>
                </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
      <!-- 客服添加 -->
      <el-col :span="12">
        <el-card shadow="hover" style="width: 650px">
          <template #header>
            <div class="card-header">
              <span>客服添加</span>
            </div>
          </template>
          <el-row>
            <el-col :span="12">
              <el-form
                class="demo-ruleForm"
                :model="form"
                :rules="rules"
                ref="ruleFormRef"
                status-icon
              >
                <el-form-item label="姓名" prop="name">
                  <el-input style="width: 120px" v-model.trim="form.name" />
                </el-form-item>

                <el-form-item label="账号" prop="account">
                  <el-input
                    class="textarea"
                    style="width: 240px"
                    :rows="3"
                    type="textarea"
                    clearable
                    placeholder="支持同时录入多账号"
                    v-model.trim="form.account"
                    @input="changeAccount"
                    @keyup.enter="Addkefu"
                  />
                </el-form-item>
                <Transition>
                  <div v-if="accountDataTable">
                    <el-text class="subhead" type="danger"
                      >注意：多账号请使用 ` / ` 隔开</el-text
                    >
                    <el-button
                      class="submitForm"
                      type="primary"
                      @click="Addkefu"
                      plain
                    >
                      确认添加
                    </el-button>
                  </div>
                </Transition>
              </el-form>
            </el-col>
            <el-col :span="12">
              <Transition>
                <div v-if="accountDataTable">
                  <el-text size="large" tag="b">{{ form.name }}</el-text>
                  <el-table :data="ObjectaccountData" style="width: 300px">
                    <el-table-column type="index" width="50" />
                    <el-table-column prop="value" width="210" />
                  </el-table>
                </div>
              </Transition>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<style lang="less" scoped>
.header {
  padding-top: 2px;
  margin-bottom: 10px;
}
.box-card {
  width: 50%;
  height: 150px;
  .el-form {
    margin-top: 10px;
  }
}
.demo-ruleForm {
  margin-top: 20px;
}
.tijiao {
  margin-left: 41px;
}
::v-deep .el-drawer {
  width: 100px;
}
.subhead {
  font-size: 12px;
  position: relative;
  left: 130px;
  bottom: 115px;
}
.submitForm {
  position: relative;
  left: 80px;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>

<script setup>
import { ElMessage } from "element-plus";
import { Edit, Delete, Search } from "@element-plus/icons-vue";
import axios from "axios";
import { reactive, ref, onMounted, Transition, watch } from "vue";
import KefuCheeckEdit from "@/components/kefucheck/KefuCheckEdit.vue";
import { useStore } from "vuex";

const store = useStore();
// 表单渲染
onMounted(() => {
  getKefuList();
});
const getKefuList = async () => {
  try {
    const res = await axios.get("/adminapi/kefu/list");
    kefuList.value = res.data.data;
    return res.data.code;
  } catch {
    return 0;
  }
};
// 客服列表
const kefuList = ref([]);

// 客服添加表单
const form = reactive({
  name: "",
  account: "",
  _id: null,
});
// 表单验证
const ruleFormRef = ref();
const rules = reactive({
  name: [
    {
      required: true,
      message: "姓名不能为空",
      trigger: "blur",
    },
  ],
  account: [
    {
      required: true,
      message: "账号不能为空",
      trigger: "blur",
    },
  ],
});
// 客服账号添加反显
const ObjectaccountData = ref([]);
const accountDataTable = ref(false);
const changeAccount = (value) => {
  if (value) {
    ObjectaccountData.value = value
      .replace(/^\/+|\/+$/g, "")
      .split("/")
      .map((item, index) => {
        return { value: item };
      });
    accountDataTable.value = true;
  } else {
    accountDataTable.value = false;
  }
};
// 客服添加
const Addkefu = () => {
  ruleFormRef.value.validate(async (value) => {
    if (value) {
      form.account = form.account.replace(/^\/+|\/+$/g, "");
      try {
        const res = await axios.post("/adminapi/kefu/add", form);
        if (res.data.code === 1) {
          getKefuList();
          ElMessage({
            message: `客服【${form.name}】添加成功`,
            type: "success",
          });
        } else {
          ElMessage({
            message: "系统错误，联系浅白。。。",
            type: "warning",
          });
        }
      } catch {
        ElMessage({
          message: "系统错误，联系浅白。。。",
          type: "warning",
        });
      }
    }
  });
};

// 客服删除
const deleteKefu = async (value) => {
  try {
    const res = await axios.delete(`/adminapi/kefu/delete/${value._id}`);
    if (res.data.code === 1) {
      getKefuList();
      ElMessage({
        message: `客服【${value.name}】删除成功`,
        type: "success",
      });
    } else {
      ElMessage({
        message: "系统错误，联系浅白。。。",
        type: "warning",
      });
    }
  } catch {
    ElMessage({
      message: "系统错误，联系浅白。。。",
      type: "warning",
    });
  }
};

// 客服编辑
const visible = ref();
const kefuEdit = (value) => {
  visible.value = null;
  store.commit("changKefuEdit", value);
};
watch(
  () => store.state.kefuEdit,
  (newvalue) => {
    if (newvalue === 1) {
      getKefuList();
      visible.value = false;
    }
  }
);

// 客服搜索
const search = ref();
const kefuSearch = async () => {
  const keyword = search.value.replace(/^\/+|\/+$/g, "");
  if (!keyword) {
    ElMessage({
      message: "请输入关键词进行搜索",
      type: "error",
    });
    getKefuList();
    return;
  }
  try {
    const res = await axios.post("/adminapi/kefu/search", {
      keyword,
    });
    if (res.data.code === 1) {
      ElMessage({
        message: "查询成功",
        type: "success",
      });
      kefuList.value = res.data.data;
    } else {
      ElMessage({
        message: "查询失败，没有相关匹配信息，请注意大小写",
        type: "warning",
      });
    }
  } catch {
    ElMessage({
      message: "查询失败，系统错误找浅白去",
      type: "warning",
    });
  }
};
</script>
