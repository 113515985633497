<template>
	<RouterView></RouterView>
</template>

<style lang="less">
	* {
		margin: 0;
		padding: 0;
	}

	::-webkit-scrollbar {
		display: none;
		position: absolute;
	}

	::-webkit-scrollbar-thumb {
		background-color: #78b2ff;
	}

	::-webkit-scrollbar-track {
		width: 0px;
		background-color: aliceblue;
	}
</style>