<template>
  <el-upload
    class="avatar-uploader"
    action=" "
    :show-file-list="false"
    :auto-upload="false"
    :on-change="handleChange"
  >
    <img v-if="props.avatar" :src="props.avatar" class="avatar" />
    <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
  </el-upload>
</template>

<style lang="less" scoped>
::v-deep.avatar-uploader .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
::v-deep.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

::v-deep.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
</style>

<script setup>
import { Plus } from "@element-plus/icons-vue";
import { defineEmits, defineProps, computed } from "vue";

const props = defineProps({ avatar: String });
const emit = defineEmits(["avatarChange"]);

// 每次选择完图片回调
const handleChange = (file) => {
  emit("avatarChange", file.raw);
};
</script>
