<template>
  <div class="header">
    <el-page-header icon="" title="员工管理">
      <template #content>
        <span class="text-Medium font-600 mr-3"> 员工列表 </span>
      </template>
    </el-page-header>
  </div>

  <div class="main">
    <el-card shadow="hover" class="box-card" style="max-width: 1100px">
      <template #header>
        <div id="card-header">
          <span>趣兔员工列表</span>
        </div>
      </template>
      <el-table
        v-loading="loading"
        :element-loading-svg="svg"
        class="custom-loading-svg"
        element-loading-svg-view-box="-10, -10, 50, 50"
        :data="tableData"
        style="width: 100%"
      >
        <el-table-column
          prop="username"
          label="用户名"
          header-align="center"
          align="center"
          width="140"
        />
        <el-table-column
          label="头像"
          header-align="center"
          align="center"
          width="140"
        >
          <template #default="scope">
            <div v-if="scope.row.avatar">
              <el-avatar
                :size="50"
                :src="scope.row.avatar"
              >
              </el-avatar>
            </div>
            <div v-else>
              <el-avatar
                :size="50"
                src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"
              />
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="petname"
          label="昵称"
          header-align="center"
          align="center"
          width="110"
        />

        <el-table-column
          label="职位"
          header-align="center"
          align="center"
          width="110"
        >
          <template #default="scope">
            <el-tag v-if="scope.row.role === 1" size="large" type="danger"
              >管理员</el-tag
            >
            <el-tag v-else-if="scope.row.role === 2" size="large" type="success"
              >资料审核</el-tag
            >
            <el-tag v-else-if="scope.row.role === 3" size="large" type="warning"
              >活动管理</el-tag
            >
            <el-tag v-else-if="scope.row.role === 4" size="large" type="primary"
              >评估主播</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="姓名"
          header-align="center"
          align="center"
          width="110"
        />
        <el-table-column prop="phoneNumber" label="手机号" width="150" />
        <el-table-column prop="WeChatAccount" label="常用微信" width="130" />
        <el-table-column
          label="操作"
          header-align="center"
          align="center"
          width="150"
          fixed="right"
        >
          <template #default="scope">
            <el-popover
              placement="left"
              :title="`用户【` + scope.row.username + `】`"
              :width="300"
              trigger="click"
            >
              <template #reference>
                <el-button size="small" @click="handleEdit(scope.row)"
                  >编辑</el-button
                >
              </template>
              <el-form
                ref="userFormRef"
                :model="userForm"
                :rules="userFormRules"
                label-width="80px"
                class="demo-ruleForm"
                status-icon
              >
                <el-form-item label="昵称" prop="petname">
                  <el-input
                    v-model.trim="userForm.petname"
                    style="width: 300px"
                  />
                </el-form-item>

                <el-form-item label="用户名" prop="username">
                  <el-input
                    disabled
                    v-model.trim="userForm.username"
                    style="width: 300px"
                  />
                </el-form-item>

                <el-form-item label="密码" prop="password">
                  <el-input
                    show-password
                    v-model.trim="userForm.password"
                    type="password"
                    style="width: 300px"
                  />
                </el-form-item>

                <el-form-item label="职位" prop="role">
                  <el-select
                    v-model="userForm.role"
                    placeholder="Select"
                    style="width: 140px"
                  >
                    <el-option
                      v-for="item in roleOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>

                <el-form-item label="个人简介" prop="introduction">
                  <el-input
                    v-model.trim="userForm.introduction"
                    type="textarea"
                    style="width: 300px"
                    maxlength="50"
                    show-word-limit
                  />
                </el-form-item>
              </el-form>
              <el-button
                style="position: relative; left: 200px"
                type="primary"
                @click="handleEditConfirm()"
              >
                确认
              </el-button>
            </el-popover>

            <el-popconfirm
              title="你确定要删除吗？"
              confirm-button-text="确定"
              cancel-button-text="取消"
              @confirm="handleDelete(scope.row)"
            >
              <template #reference>
                <el-button size="small" type="danger">删除</el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<style lang="less" scoped>
.header {
  padding-top: 2px;
  margin-bottom: 10px;
}
#card-header span {
  font-size: 20px;
  font-weight: 550;
  color: #606266;
  margin-right: 50px;
}

::v-deep .el-dropdown__popper {
  border-radius: 20px;
}
</style>

<script setup>
import { ElMessage } from "element-plus";
import { ref, onMounted, reactive } from "vue";
import axios from "axios";

const tableData = ref([]);
// 进入页面便获取数据
onMounted(() => {
  getTableData();
});

// 获取所有用户信息
const getTableData = async () => {
  try {
    loading.value = true;
    const res = await axios.get("adminapi/user/list");
    if (res.data.code === 1) {
      tableData.value = res.data.data;
      loading.value = false;
    } else {
      ElMessage.error("数据获取失败，" + res.data.err);
    }
  } catch (err) {
    ElMessage.error("数据获取失败，" + err);
  }
};

// 编辑
const handleEdit = async (data) => {
  const res = await axios.get(`adminapi/user/list/${data._id}`);
  userForm.value = res.data.data[0];
};
// 编辑确认
const handleEditConfirm = () => {
  userFormRef.value.validate(async (valid) => {
    if (valid) {
      try {
        const res = await axios.put(
          `adminapi/user/list/${userForm._id}`,
          userForm
        );
        if (res.data.code === 1) {
          ElMessage({
            message: "修改成功",
            type: "success",
          });
          getTableData();
        } else {
          ElMessage.error("数据获取失败，" + res.data.err);
        }
      } catch (err) {
        ElMessage.error("数据获取失败，" + err);
      }
    }
  });
};
// 删除
const handleDelete = async (data) => {
  try {
    const res = await axios.delete(`adminapi/user/list/${data._id}`);
    if (res.data.code === 1) {
      // 重新获取数据渲染
      getTableData();
      ElMessage({
        message: "员工删除成功",
        type: "success",
      });
    } else {
      ElMessage.error("后端发生错误，员工删除失败" + res.data.err);
    }
  } catch (err) {
    ElMessage.error("员工删除失败，" + err);
  }
};

const userFormRef = ref();
const userForm = ref({
  petname: "",
  username: "",
  password: "",
  role: null,
  introduction: "",
});
const userFormRules = reactive({
  petname: [{ required: true, message: "请输入昵称", trigger: "blur" }],
  username: [{ required: true, message: "请输入用户名", trigger: "blur" }],
  password: [
    {
      required: true,
      message: "密码不能为空",
      trigger: "blur",
    },
    {
      pattern: /^.{8,15}$/,
      message: "密码长度为8~15位",
    },
  ],
  role: [{ required: true, message: "选择职位", trigger: "blur" }],
});
const roleOptions = [
  { label: "管理员", value: 1 },
  { label: "资料审核", value: 2 },
  { label: "活动管理", value: 3 },
  { label: "评估主播", value: 4 },
];

// 加载动画
//加载动画
const loading = ref(true);
const svg = `
        <path class="path" d="
          M 30 15
          L 28 17
          M 25.61 25.61
          A 15 15, 0, 0, 1, 15 30
          A 15 15, 0, 1, 1, 27.99 7.5
          L 15 15
        " style="stroke-width: 4px; fill: rgba(0, 0, 0, 0)"/>
      `;
</script>
