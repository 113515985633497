import axios from "axios";
function upload(path, Form) {
  // 对象中存在文件时，需要将对象循环写入FormData（将图片文件分段发出）
  const params = new FormData();
  for (let i in Form) {
    params.append(i, Form[i]);
  }
  return axios.post(path, params, {
    headers: {
      // 配置相应请求头
      "Content-Type": "multipart/form-data",
    },
  });
}

export default upload;
